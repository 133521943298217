import React from 'react';
import T from 'prop-types';
import Classes from './styles';

const PinIt = ({ href, imageUrl, description }) => {
  if (!window.allowSharing) { return null; }

  const onClick = (e) => {
    e.preventDefault();
    if (window.PinUtils) {
      window.PinUtils.pinOne({
        url: href,
        media: imageUrl,
        description
      });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" className={ Classes.pinClass } onClick={ onClick }>
      <img
        src="//assets.pinterest.com/images/pidgets/pinit_fg_en_round_red_32.png"
        alt="PinIt"
        width="16px"
        height="16px"
      />
    </a>
  );
};

PinIt.propTypes = {
  href: T.string,
  imageUrl: T.string,
  description: T.string
};

export default PinIt;
